import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { useState, useEffect } from "react";

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(timezone);

interface IEventTimeDetails {
  formattedDate: string;
  formattedTime: string;
  timeZone: string;
}

const useConvertTimeToLocal = (
  eventDate: string,
  eventTime: string,
  userTimeZone: string = "local"
): IEventTimeDetails => {
  const [eventDetails, setEventDetails] = useState<IEventTimeDetails>({
    formattedDate: "",
    formattedTime: "",
    timeZone: "",
  });

  useEffect(() => {
    const eventDateTime = dayjs(`${eventDate}T${eventTime}+00:00`).utc();
    const finalTimeZone =
      userTimeZone === "local"
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : userTimeZone;

    const userTime = eventDateTime.tz(finalTimeZone);

    const timeZoneAbbreviation =
      timeZoneMapping[finalTimeZone] ||
      new Intl.DateTimeFormat("en-GB", {
        timeZone: finalTimeZone,
        timeZoneName: "short",
      }).format(userTime.toDate());

    setEventDetails({
      formattedDate: userTime.format("MMMM D, YYYY"),
      formattedTime: userTime.format("h:mm A"),
      timeZone: timeZoneAbbreviation,
    });
  }, [eventDate, eventTime, userTimeZone]);

  return eventDetails;
};

interface ITimeZoneMapping {
  [key: string]: string;
}
const timeZoneMapping: ITimeZoneMapping = {
  "Africa/Abidjan": "GMT",
  "Africa/Accra": "GMT",
  "Africa/Addis_Ababa": "EAT",
  "Africa/Algiers": "CET",
  "Africa/Asmara": "EAT",
  "Africa/Bamako": "GMT",
  "Africa/Bangui": "WAT",
  "Africa/Banjul": "GMT",
  "Africa/Bissau": "GMT",
  "Africa/Blantyre": "CAT",
  "Africa/Bujumbura": "CAT",
  "Africa/Cairo": "EET",
  "Africa/Casablanca": "WET",
  "Africa/Ceuta": "CET",
  "Africa/Conakry": "GMT",
  "Africa/Dakar": "GMT",
  "Africa/Dar_es_Salaam": "EAT",
  "Africa/Djibouti": "EAT",
  "Africa/Douala": "WAT",
  "Africa/El_Aaiun": "WET",
  "Africa/Freetown": "GMT",
  "Africa/Gaborone": "CAT",
  "Africa/Harare": "CAT",
  "Africa/Johannesburg": "SAST",
  "Africa/Juba": "CAT",
  "Africa/Kampala": "EAT",
  "Africa/Khartoum": "CAT",
  "Africa/Kigali": "CAT",
  "Africa/Kinshasa": "WAT",
  "Africa/Lagos": "WAT",
  "Africa/Libreville": "WAT",
  "Africa/Luanda": "WAT",
  "Africa/Lubumbashi": "CAT",
  "Africa/Lusaka": "CAT",
  "Africa/Malabo": "WAT",
  "Africa/Maputo": "CAT",
  "Africa/Maseru": "SAST",
  "Africa/Mbabane": "SAST",
  "Africa/Mogadishu": "EAT",
  "Africa/Monrovia": "GMT",
  "Africa/Nairobi": "EAT",
  "Africa/Ndjamena": "WAT",
  "Africa/Niamey": "WAT",
  "Africa/Nouakchott": "GMT",
  "Africa/Ouagadougou": "GMT",
  "Africa/Porto-Novo": "WAT",
  "Africa/Sao_Tome": "GMT",
  "Africa/Tunis": "CET",
  "Africa/Windhoek": "CAT",
  "America/Anchorage": "AKST",
  "America/Argentina/Buenos_Aires": "ART",
  "America/Argentina/Catamarca": "ART",
  "America/Argentina/Cordoba": "ART",
  "America/Argentina/Jujuy": "ART",
  "America/Argentina/La_Rioja": "ART",
  "America/Argentina/Mendoza": "ART",
  "America/Argentina/Rio_Gallegos": "ART",
  "America/Argentina/Salta": "ART",
  "America/Argentina/San_Juan": "ART",
  "America/Argentina/San_Luis": "ART",
  "America/Argentina/Tucuman": "ART",
  "America/Argentina/Ushuaia": "ART",
  "America/Asuncion": "PYST",
  "America/Bahia": "BRT",
  "America/Barbados": "AST",
  "America/Belem": "BRT",
  "America/Belize": "CST",
  "America/Blanc-Sablon": "AST",
  "America/Bogota": "COT",
  "America/Boise": "MST",
  "America/Cambridge_Bay": "MST",
  "America/Campo_Grande": "AMT",
  "America/Cancun": "EST",
  "America/Caracas": "VET",
  "America/Cayenne": "GFT",
  "America/Cayman": "EST",
  "America/Chicago": "CST",
  "America/Chihuahua": "MST",
  "America/Coral_Harbour": "EST",
  "America/Costa_Rica": "CST",
  "America/Creston": "MST",
  "America/Cuiaba": "AMT",
  "America/Curacao": "AST",
  "America/Danmarkshavn": "GMT",
  "America/Dawson": "PST",
  "America/Dawson_Creek": "MST",
  "America/Denver": "MST",
  "America/Detroit": "EST",
  "America/Dominica": "AST",
  "America/Edmonton": "MST",
  "America/Eirunepe": "ACT",
  "America/El_Salvador": "CST",
  "America/Fortaleza": "BRT",
  "America/Glace_Bay": "AST",
  "America/Goose_Bay": "AST",
  "America/Grand_Turk": "EST",
  "America/Grenada": "AST",
  "America/Guadeloupe": "AST",
  "America/Guatemala": "CST",
  "America/Guayaquil": "ECT",
  "America/Havana": "CST",
  "America/Hermosillo": "MST",
  "America/Indianapolis": "EST",
  "America/Inuvik": "MST",
  "America/Iqaluit": "EST",
  "America/Jamaica": "EST",
  "America/Juneau": "AKST",
  "America/Kentucky/Louisville": "EST",
  "America/Kentucky/Monticello": "EST",
  "America/La_Paz": "BOT",
  "America/Lima": "PET",
  "America/Los_Angeles": "PST",
  "America/Louisville": "EST",
  "America/Maceio": "BRT",
  "America/Managua": "CST",
  "America/Manaus": "AMT",
  "America/Marigot": "AST",
  "America/Martinique": "AST",
  "America/Matamoros": "CST",
  "America/Mexico_City": "CST",
  "America/Moncton": "AST",
  "America/Montevideo": "UYT",
  "America/Nassau": "EST",
  "America/New_York": "EST",
  "America/Nipigon": "EST",
  "America/Panama": "EST",
  "America/Pangnirtung": "EST",
  "America/Paramaribo": "SRT",
  "America/Phoenix": "MST",
  "America/Port-au-Prince": "EST",
  "America/Port_of_Spain": "AST",
  "America/Recife": "BRT",
  "America/Regina": "CST",
  "America/Rosario": "ART",
  "America/Sao_Paulo": "BRT",
  "America/Scoresbysund": "GMT",
  "America/Shiprock": "MST",
  "America/Sitka": "AKST",
  "America/St_Johns": "NST",
  "America/Swift_Current": "CST",
  "America/Tegucigalpa": "CST",
  "America/Thule": "AST",
  "America/Toronto": "EST",
  "America/Vancouver": "PST",
  "America/Whitehorse": "AKST",
  "America/Winnipeg": "CST",
  "America/Yakutat": "AKST",
  "America/Zapopan": "CST",
  "Asia/Baghdad": "AST",
  "Asia/Bahrain": "AST",
  "Asia/Baku": "AZT",
  "Asia/Barnaul": "KRAT",
  "Asia/Beirut": "EET",
  "Asia/Bishkek": "KGT",
  "Asia/Brunei": "BNT",
  "Asia/Calcutta": "IST",
  "Asia/Chita": "YAKT",
  "Asia/Choibalsan": "ULAT",
  "Asia/Colombo": "LKT",
  "Asia/Damascus": "EET",
  "Asia/Dhaka": "BST",
  "Asia/Dili": "TLT",
  "Asia/Dubai": "GST",
  "Asia/Gaza": "EET",
  "Asia/Ho_Chi_Minh": "ICT",
  "Asia/Hong_Kong": "HKT",
  "Asia/Irkutsk": "IRKT",
  "Asia/Jakarta": "WIB",
  "Asia/Jayapura": "WIT",
  "Asia/Jerusalem": "IST",
  "Asia/Kabul": "AFT",
  "Asia/Kamchatka": "PETT",
  "Asia/Karachi": "PKT",
  "Asia/Kathmandu": "NPT",
  "Asia/Kolkata": "IST",
  "Asia/Krasnoyarsk": "KRAT",
  "Asia/Kuala_Lumpur": "MYT",
  "Asia/Kuwait": "AST",
  "Asia/Macau": "CST",
  "Asia/Magadan": "MAGT",
  "Asia/Makassar": "WITA",
  "Asia/Manila": "PHT",
  "Asia/Muscat": "GST",
  "Asia/Nicosia": "EET",
  "Asia/Novosibirsk": "NOVT",
  "Asia/Omsk": "OMST",
  "Asia/Oral": "ORAT",
  "Asia/Qatar": "AST",
  "Asia/Riyadh": "AST",
  "Asia/Sakhalin": "SAKT",
  "Asia/Samarkand": "UZT",
  "Asia/Seoul": "KST",
  "Asia/Singapore": "SGT",
  "Asia/Taipei": "CST",
  "Asia/Tashkent": "UZT",
  "Asia/Tbilisi": "GET",
  "Asia/Tehran": "IRST",
  "Asia/Tokyo": "JST",
  "Asia/Ulaanbaatar": "ULAT",
  "Asia/Urumqi": "CST",
  "Asia/Vientiane": "ICT",
  "Asia/Vladivostok": "VLAT",
  "Asia/Yakutsk": "YAKT",
  "Asia/Yangon": "MMT",
  "Australia/Adelaide": "ACST",
  "Australia/Brisbane": "AEST",
  "Australia/Darwin": "ACST",
  "Australia/Hobart": "AEDT",
  "Australia/Lindeman": "AEST",
  "Australia/Melbourne": "AEDT",
  "Australia/Perth": "AWST",
  "Australia/Sydney": "AEDT",
  "Europe/Amsterdam": "CET",
  "Europe/Andorra": "CET",
  "Europe/Athens": "EET",
  "Europe/Belgrade": "CET",
  "Europe/Berlin": "CET",
  "Europe/Brussels": "CET",
  "Europe/Bucharest": "EET",
  "Europe/Budapest": "CET",
  "Europe/Chisinau": "EET",
  "Europe/Copenhagen": "CET",
  "Europe/Dublin": "GMT",
  "Europe/Gibraltar": "CET",
  "Europe/Guernsey": "GMT",
  "Europe/Helsinki": "EET",
  "Europe/Isle_of_Man": "GMT",
  "Europe/Istanbul": "TRT",
  "Europe/Jersey": "GMT",
  "Europe/Kaliningrad": "USZ1",
  "Europe/Kiev": "EET",
  "Europe/Lisbon": "WET",
  "Europe/Ljubljana": "CET",
  "Europe/London": "GMT",
  "Europe/Luxembourg": "CET",
  "Europe/Madrid": "CET",
  "Europe/Malta": "CET",
  "Europe/Mariehamn": "EET",
  "Europe/Minsk": "MSK",
  "Europe/Monaco": "CET",
  "Europe/Moscow": "MSK",
  "Europe/Oslo": "CET",
  "Europe/Paris": "CET",
  "Europe/Podgorica": "CET",
  "Europe/Prague": "CET",
  "Europe/Riga": "EET",
  "Europe/Rome": "CET",
  "Europe/Samara": "SAMT",
  "Europe/San_Marino": "CET",
  "Europe/Sarajevo": "CET",
  "Europe/Simferopol": "MSK",
  "Europe/Skopje": "CET",
  "Europe/Sofia": "EET",
  "Europe/Stockholm": "CET",
  "Europe/Tallinn": "EET",
  "Europe/Tirane": "CET",
  "Europe/Uzhgorod": "EET",
  "Europe/Vaduz": "CET",
  "Europe/Vatican": "CET",
  "Europe/Vienna": "CET",
  "Europe/Vilnius": "EET",
  "Europe/Volgograd": "MSK",
  "Europe/Warsaw": "CET",
  "Europe/Zagreb": "CET",
  "Europe/Zaporozhye": "EET",
  "Indian/Antananarivo": "EAT",
  "Indian/Chagos": "IOT",
  "Indian/Christmas": "CXT",
  "Indian/Cocos": "CCT",
  "Indian/Comoro": "EAT",
  "Indian/Kerguelen": "TFT",
  "Indian/Mahe": "SCT",
  "Indian/Maldives": "MVT",
  "Indian/Reunion": "RET",
  "Pacific/Apia": "SST",
  "Pacific/Auckland": "NZDT",
  "Pacific/Chatham": "CHADT",
  "Pacific/Easter": "EAST",
  "Pacific/Enderbury": "TVT",
  "Pacific/Fakaofo": "TKT",
  "Pacific/Fiji": "FJT",
  "Pacific/Funafuti": "FJT",
  "Pacific/Galapagos": "GALT",
  "Pacific/Gambier": "GAMT",
  "Pacific/Guam": "ChST",
  "Pacific/Honolulu": "HST",
  "Pacific/Kiritimati": "KIRITIMATI",
  "Pacific/Majuro": "MHT",
  "Pacific/Marquesas": "MART",
  "Pacific/Nauru": "NRT",
  "Pacific/Niue": "NUT",
  "Pacific/Norfolk": "NFT",
  "Pacific/Port_Moresby": "PGT",
  "Pacific/Rarotonga": "CKT",
  "Pacific/Tahiti": "TAHT",
  "Pacific/Tarawa": "GILT",
  "Pacific/Tongatapu": "TOT",
  "Pacific/Wake": "WAKT",
  "Pacific/Wallis": "WFT",
};

export default useConvertTimeToLocal;
